import React, { useRef, useEffect, useState } from "react"
import { isIndex } from "../Common/utils"
import {
  DEFAULT_MEDIA_SCALE,
  DEFAULT_MEDIA_OPACITY,
} from "../Store/SlideDBTemplate"
import { HMFileIO } from "../Common/HMFileIO"
import { RiListCheck2 } from "react-icons/ri"
import { FaLink, FaRegFileVideo, FaRegFileAudio } from "react-icons/fa"
import { HMKText, HMKFont, HMKImage } from "./HMKSVGTools"

// font definition for slide content (custom content)
const PartNameFont = HMKFont("Calibri, Bold", "14px", "bold", "white")
const PartPickNameFont = HMKFont("Calibri", "13px", "normal", "white")
const PartPickCountFont = HMKFont("Calibri, Bold", "30px", "bold", "white")

// font definition for slide template (generic content)
// eslint-disable-next-line no-unused-vars
const SlideCounterFont = HMKFont("Calibri", "14px", "normal", "white")
const TitleFont = HMKFont("Calibri, Bold", "18px", "bold", "white")
const SubtitleFont = HMKFont("Calibri", "14px", "normal", "white")

const DEBUG = false
const MOUSE_UNSELECTION = true
const MOUSE_SELECTION = false
const MOUSE_SCALE = true

const ContentLayout = ({
  width,
  height,
  content,
  selected,
  index,
  draggable,
  lockedMedia,
}) => {
  useEffect(() => {
    if (DEBUG)
      console.log("REDRAW ContentLayout with content URL ", content.part_url)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content])

  return (
    <g
      id={"content_" + index}
      index={index}
      style={{ pointerEvents: lockedMedia ? "none" : "auto" }}
    >
      {content.part_url && (
        <HMKImage
          width={content.width ? content.width : width}
          height={content.height ? content.height : height}
          compositing={content.compositing ? content.compositing : "NORMAL"}
          url={content.part_url}
          x={content.x}
          y={content.y}
          angle={content.angle}
          opacity={content.opacity ? content.opacity : DEFAULT_MEDIA_OPACITY} // compatibility fallback for old data
          scale={content.scale ? content.scale : DEFAULT_MEDIA_SCALE} // compatibility fallback for old data
          selected={selected}
          draggable={draggable}
        />
      )}
      {content.compo_url && (
        <HMKImage
          isFromGeometry
          width={content.width ? content.width : width}
          height={content.height ? content.height : height}
          compositing={content.compositing ? content.compositing : "NORMAL"}
          url={content.compo_url}
          x={content.x}
          y={content.y}
          angle={content.angle}
          opacity={content.opacity ? content.opacity : DEFAULT_MEDIA_OPACITY} // compatibility fallback for old data
          scale={content.scale ? content.scale : DEFAULT_MEDIA_SCALE} // compatibility fallback for old data
          selected={selected}
          draggable={draggable}
        />
      )}
    </g>
  )
}

const GlobalLayout = ({ width, height, template, counter, content }) => (
  <g id="global">
    {/** Step name */}
    {template.partnamePos && template.partnamePos.show && (
      <HMKText
        width={width}
        height={height}
        margin={template.margin}
        font={PartNameFont}
        position={template.partnamePos}
        text={content.part_name}
      />
    )}
    {/** counter */}
    {counter && template.counterPos && template.counterPos.show && (
      <HMKText
        width={width}
        height={height}
        margin={template.margin}
        font={SlideCounterFont}
        position={template.counterPos}
        text={counter.index + " / " + counter.total}
        dynamic_offsetY={{ text: content.part_name, fontSize: 14 }}
      />
    )}
    {/** top center */}
    {counter && template.partpickcountPos && template.partpickcountPos.show && (
      <HMKText
        width={width}
        height={height}
        margin={template.margin}
        font={PartPickCountFont}
        position={template.partpickcountPos}
        text={content.part_pick_count + "x"}
      />
    )}
    {/** descrption */}
    {template.partpicknamePos && template.partpicknamePos.show && (
      <HMKText
        width={width}
        height={height}
        margin={template.margin}
        font={PartPickNameFont}
        position={template.partpicknamePos}
        text={content.part_pick_name}
      />
    )}
    {template.titlePos.show && (
      <HMKText
        width={width}
        height={height}
        margin={template.margin}
        font={TitleFont}
        position={template.titlePos}
        text={template.title}
      />
    )}
    {template.subtitlePos.show && (
      <HMKText
        width={width}
        height={height}
        margin={template.margin}
        font={SubtitleFont}
        position={template.subtitlePos}
        text={template.subtitle}
        dynamic_offsetY={{ text: template.title, fontSize: 18 }}
      />
    )}
  </g>
)

const UX_MODE = { NONE: "none", MOVE: "move", SCALE: "scale" }
const UX_SCALE_KNOT = {
  NW: "north-west",
  SW: "south-west",
  NE: "north-east",
  SE: "south-east",
}
let cache_mousePos = ""
const HMKSVGSlide = ({
  dispatch,
  template,
  item,
  counter,
  viewBox,
  selected,
  setSelected,
  lockedMedia,
  activeKeys,
  openModal,
}) => {
  const svgElt = useRef(null)
  const [content, setContent] = useState(null)
  const [dragOffset, setDragOffset] = useState(null)
  const [uxMode, setUxMode] = useState(UX_MODE.NONE)
  const [uxScaleKnot, setUxScaleKnot] = useState(undefined)
  const [initialValues, setInitialValues] = useState()
  const [scaleMode, setScaleMode] = useState(false)
  const refContent = useRef(null)

  const getMousePosition = (evt) => {
    var CTM = svgElt.current.getScreenCTM()
    const mp = {
      x: (evt.clientX - CTM.e) / CTM.a,
      y: (evt.clientY - CTM.f) / CTM.d,
    }
    return mp
  }

  useEffect(() => {
    if (DEBUG) console.log("HMKSVGSlide : update item props to ", item)
  }, [item])
  useEffect(() => {
    if (DEBUG) console.log("HMKSVGSlide : update content to ", content)
    refContent.current = content
  }, [content])
  useEffect(() => {
    if (isIndex(selected)) setContent(item.content[selected])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, item.content])

  // main effect for user interaction on slide content
  // start drag & end drag
  useEffect(() => {
    if (dispatch && isIndex(selected)) {
      // Start drag = select element and store mouse position
      const startDrag = (event) => {
        if (event.button !== 0) {
          return
        }
        const { x, y } = getMousePosition(event)
        cache_mousePos = `${Math.round(x)},${Math.round(y)}`
        let _selIdx = -1
        let _uxMode
        setScaleMode(activeKeys.includes("s"))
        if (DEBUG) console.log("START DRAG : selected element = ", event.target)
        if (
          event.target.classList.contains("resizable-nw") ||
          event.target.classList.contains("resizable-se") ||
          event.target.classList.contains("resizable-ne") ||
          event.target.classList.contains("resizable-sw")
        ) {
          if (!MOUSE_SCALE) return
          // check the selected widget on media content (handles)
          const index =
            event.target.parentNode.parentNode.parentNode.getAttribute("index")
          _selIdx = parseInt(index, 10)
          _uxMode = UX_MODE.SCALE
          const scaleKnot = event.target.classList.contains("resizable-nw")
            ? UX_SCALE_KNOT.NW
            : event.target.classList.contains("resizable-se")
            ? UX_SCALE_KNOT.SE
            : event.target.classList.contains("resizable-ne")
            ? UX_SCALE_KNOT.NE
            : UX_SCALE_KNOT.SW
          // BE CAREFULL: width and height are not set by default
          const localContent = { ...item.content[_selIdx] }
          if (!localContent.width || !localContent.height) {
            localContent.width = viewBox[2]
            localContent.height = viewBox[3]
          }
          // fallback old data : scale is not set
          if (!localContent.scale) localContent.scale = DEFAULT_MEDIA_SCALE
          MOUSE_SELECTION && setSelected(_selIdx)
          setUxMode(_uxMode)
          setUxScaleKnot(scaleKnot)
          setContent(localContent)
          setInitialValues({
            width: localContent.width,
            height: localContent.height,
            ratio: localContent.width / localContent.height,
            scale: localContent.scale,
          })
        } else if (event.target.classList.contains("draggable")) {
          // check the selected media content
          const index = event.target.parentNode.parentNode.getAttribute("index")
          _selIdx = parseInt(index, 10)
          _uxMode = UX_MODE.MOVE
          MOUSE_SELECTION && setSelected(_selIdx)
          MOUSE_SELECTION && setContent({ ...item.content[_selIdx] })
          setUxMode(_uxMode)
        } else {
          _uxMode = UX_MODE.NONE
          setUxMode(_uxMode)
          MOUSE_UNSELECTION && setSelected(-1)
          MOUSE_UNSELECTION && setContent(null)
          setDragOffset(null)
          refContent.current = null
        }
        if (_uxMode !== UX_MODE.NONE) {
          setDragOffset({ x: x, y: y }) //getMousePosition(event)
        }
      }

      // stop edition and send new position results back to parent
      const endDrag = (event) => {
        if (DEBUG) console.log(`End Drag event `, event.type)
        if (dragOffset && uxMode !== UX_MODE.NONE && refContent.current) {
          const minScale = 5
          if (initialValues) {
            let scale_xDelta =
              (refContent.current.width / initialValues.width) * 100
            let scale_yDelta =
              (refContent.current.height / initialValues.height) * 100
            if (scale_xDelta && scale_yDelta) {
              let delta, scale
              if (
                Math.abs(refContent.current.scale - scale_xDelta) >
                Math.abs(refContent.current.scale - scale_yDelta)
              ) {
                delta = scale_xDelta
              } else if (
                Math.abs(refContent.current.scale - scale_yDelta) >
                Math.abs(refContent.current.scale - scale_xDelta)
              ) {
                delta = scale_yDelta
              }
              refContent.current.width = initialValues.width
              refContent.current.height = initialValues.height
              scale = refContent.current.scale * delta * 0.01
              if (scale < minScale) {
                scale = minScale
              }
              if (scale) {
                refContent.current.scale = scale
              }
            }
          }
          const { x, y } = getMousePosition(event)
          if (cache_mousePos !== `${Math.round(x)},${Math.round(y)}`) {
            dispatch({
              type: "UPDATE_SLIDE_CONTENT",
              payload: { seq_id: item.seq_id, content: refContent.current },
            })
          }
        }
        cache_mousePos = ""
        setUxMode(UX_MODE.NONE)
        setUxScaleKnot(undefined)
        setDragOffset(null)
        setInitialValues(null)
        setScaleMode(false)
        MOUSE_SELECTION && setContent(null)
      }

      const svgDiv = svgElt.current
      svgDiv.addEventListener("mousedown", startDrag)
      svgDiv.addEventListener("mouseup", endDrag)
      svgDiv.addEventListener("mouseleave", endDrag)
      return () => {
        svgDiv.removeEventListener("mousedown", startDrag)
        svgDiv.removeEventListener("mouseup", endDrag)
        svgDiv.removeEventListener("mouseleave", endDrag)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, item, dragOffset, uxMode, viewBox, activeKeys, selected])

  // key down effect
  useEffect(() => {
    if (dispatch) {
      const onKeyDown = (event) => {
        switch (event.key) {
          // PgUp to go up one slide
          case "Delete": {
            event.preventDefault()
            if (isIndex(selected)) {
              const cleanup = () => {
                // reinit selection
                setUxMode(UX_MODE.NONE)
                setUxScaleKnot(undefined)
                setDragOffset(null)
                setSelected(-1)
                setContent(null)
              }
              const trigger_delete_content = () => {
                dispatch({
                  type: "REMOVE_SLIDE_CONTENT",
                  payload: { seq_id: item.seq_id, index: selected },
                })
              }

              const content = item.content[selected]
              if (!content.part_url) {
                trigger_delete_content()
              } else {
                HMFileIO().imageValidityCheck(content.part_url).then((res) => {
                  if (!res) {
                    // delete without opening modal is URL is invalid
                    trigger_delete_content()
                  } else {
                    openModal({
                      title: "Removing image",
                      message: "Are you sure you want to remove this image ?",
                      children: (
                        <div className="form-media-thumbnail-container">
                          <img
                            src={content.part_url}
                            alt="preview"
                            style={{ cursor: "unset" }}
                          />
                        </div>
                      ),
                      onSubmit: () => {
                        trigger_delete_content()
                        cleanup()
                      },
                    })
                  }
                })
              }
            }
            break
          }
          default:
        }
      }
      const svgDiv = svgElt.current
      // register keypress event handler
      svgDiv.parentNode.addEventListener("keydown", onKeyDown)
      return () => {
        svgDiv.parentNode.removeEventListener("keydown", onKeyDown)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, item, content, selected])

  // drag
  useEffect(() => {
    // update selected element position
    if (dispatch && isIndex(selected)) {
      const drag = (event) => {
        if (event.buttons !== 1) {
          return
        }
        if (
          dragOffset &&
          content &&
          refContent.current &&
          refContent.current.layer_id === content.layer_id
        ) {
          event.preventDefault()
          const coords = getMousePosition(event)
          let localContent = refContent.current
          if (scaleMode) {
            const delta_x = coords.x - dragOffset.x
            const delta_y = coords.y - dragOffset.y
            const delta = delta_x + delta_y
            if (!delta) return
            const sign = delta < 0 ? -1 : 1
            const scale_offset = Math.sqrt(Math.abs(delta_x + delta_y)) * sign
            const prev_scale = refContent.current.scale
            const new_scale = refContent.current.scale + scale_offset
            if (new_scale < 1 || new_scale > 100) {
              setDragOffset(coords)
              return
            }
            const new_scale_coeff = new_scale / 100

            const prev_scale_coeff = prev_scale / 100
            const prev_X = refContent.current.x
            const prev_Y = refContent.current.y
            const media_half_width = refContent.current.width / 2
            const media_half_height = refContent.current.height / 2
            const center_x = prev_X + media_half_width * prev_scale_coeff
            const center_y = prev_Y + media_half_height * prev_scale_coeff
            const offset_x = new_scale_coeff * media_half_width
            const offset_y = new_scale_coeff * media_half_height
            setContent((oldContent) => ({
              ...oldContent,
              x: center_x - offset_x,
              y: center_y - offset_y,
              scale: new_scale,
            }))
            setDragOffset(coords)
            return
          }
          let needUpdate = false
          if (uxMode === UX_MODE.MOVE) {
            localContent.x += coords.x - dragOffset.x
            localContent.y += coords.y - dragOffset.y
            needUpdate = true
          }
          let inverseScale = refContent.current.scale
            ? 100 / refContent.current.scale
            : 1
          let xDelta = (coords.x - dragOffset.x) * inverseScale
          let yDelta = (coords.y - dragOffset.y) * inverseScale
          let newWidth, newHeight
          const minSize = 20
          if (uxScaleKnot === UX_SCALE_KNOT.SE) {
            newWidth = localContent.width + xDelta
            newHeight = localContent.height + yDelta
            if (newWidth < minSize) {
              localContent.x += xDelta / inverseScale
            }
            if (newHeight < minSize) {
              localContent.y += yDelta / inverseScale
            }
            needUpdate = true
          } else if (uxScaleKnot === UX_SCALE_KNOT.NE) {
            newWidth = localContent.width + xDelta
            newHeight = localContent.height - yDelta
            localContent.y += yDelta / inverseScale
            if (newWidth < minSize) {
              localContent.x += xDelta / inverseScale
            }
            needUpdate = true
          } else if (uxScaleKnot === UX_SCALE_KNOT.SW) {
            newWidth = localContent.width - xDelta
            newHeight = localContent.height + yDelta
            localContent.x += xDelta / inverseScale
            if (newHeight < minSize) {
              localContent.y += yDelta / inverseScale
            }
            needUpdate = true
          } else if (uxScaleKnot === UX_SCALE_KNOT.NW) {
            newWidth = localContent.width - xDelta
            newHeight = localContent.height - yDelta
            localContent.x += xDelta / inverseScale
            localContent.y += yDelta / inverseScale
            needUpdate = true
          }
          if (needUpdate) {
            if (newWidth) {
              localContent.width = newWidth >= minSize ? newWidth : minSize
            }
            if (newHeight) {
              localContent.height = newHeight >= minSize ? newHeight : minSize
            }
            setContent((oldContent) => ({
              ...oldContent,
              x: localContent.x,
              y: localContent.y,
              width: localContent.width,
              height: localContent.height,
            }))
            setDragOffset(coords)
            if (DEBUG)
              console.log(
                "HMKSVGSLIDE : updated content position/size to ",
                localContent
              )
          }
        }
      }
      const svgDiv = svgElt.current
      svgDiv.addEventListener("mousemove", drag)
      return () => {
        svgDiv.removeEventListener("mousemove", drag)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, dragOffset, uxMode, uxScaleKnot, content, scaleMode, selected])

  const contentToDraw =
    content && isIndex(selected)
      ? [
          ...item.content.slice(0, selected),
          content,
          ...item.content.slice(selected + 1),
        ]
      : item.content
  return (
    <div
      style={{
        width: "100%",
        overflow: "visible",
      }}
      tabIndex={0}
    >
      <svg
        ref={svgElt}
        id={"svg_slide_" + item.seq_id}
        viewBox={viewBox.join(" ")}
        preserveAspectRatio="xMinYMin meet"
      >
        <defs>HoloMake touch</defs>
        {contentToDraw
          .slice(0)
          .reverse()
          .map((cont, i) => (
            <ContentLayout
              // part_picture_id is unique
              key={`${cont.part_picture_id}${contentToDraw.length - 1 - i}`}
              width={viewBox[2]}
              height={viewBox[3]}
              content={cont}
              selected={selected === contentToDraw.length - 1 - i}
              index={contentToDraw.length - 1 - i}
              lockedMedia={
                lockedMedia &&
                lockedMedia.includes(cont.layer_id + cont.part_picture_id)
              }
              draggable={dispatch}
            />
          ))}
        <GlobalLayout
          width={viewBox[2]}
          height={viewBox[3]}
          template={template}
          counter={counter}
          content={
            contentToDraw && contentToDraw.length ? contentToDraw[0] : null
          }
        />
      </svg>
      <div
        className={`picto-on-slide-container ${
          Boolean(dispatch)
            ? "picto-on-slide-container-detail"
            : "picto-on-slide-container-master"
        }`}
      >
        {item.links && item.links.length ? (
          <FaLink
            className={`${
              Boolean(dispatch)
                ? "picto-on-slide-detail"
                : "picto-on-slide-master"
            }`}
          />
        ) : (
          <></>
        )}
        {item.checklist && item.checklist.length ? (
          <RiListCheck2
            className={`${
              Boolean(dispatch)
                ? "picto-on-slide-detail"
                : "picto-on-slide-master"
            }`}
          />
        ) : (
          <></>
        )}
        {item.video_clip ? (
          <FaRegFileVideo
            className={`${
              Boolean(dispatch)
                ? "picto-on-slide-detail"
                : "picto-on-slide-master"
            }`}
          />
        ) : (
          <></>
        )}
        {item.audio_clip ? (
          <FaRegFileAudio
            className={`${
              Boolean(dispatch)
                ? "picto-on-slide-detail"
                : "picto-on-slide-master"
            }`}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default HMKSVGSlide
