import React, { useState, useEffect, useRef, useContext, useMemo } from "react"
import { FiLink, FiTrash2 } from "react-icons/fi"
import {
  FaArrowUp,
  FaArrowDown,
  FaCheck,
  FaLock,
  FaLockOpen,
  FaUndo,
  FaPlus,
  FaMinus,
  FaEdit,
} from "react-icons/fa"
import { MdClose } from "react-icons/md"
import { TiPencil } from "react-icons/ti"
import { AuthUserContext } from "../Authentication/Session/Session"
import { withFirebase } from "../Authentication/Firebase"
import { withRouter } from "react-router-dom"
import { compose } from "recompose"
import { withAuthorization } from "../Authentication/Session/Session"
import Slider from "rc-slider"
import "./SliderStyle.css"
import {
  _compositing,
  DEFAULT_MEDIA_SCALE,
  DEFAULT_MEDIA_OPACITY,
  DEFAULT_SLIDE_DETECTION_THRESHOLD_LOW,
  DEFAULT_SLIDE_DETECTION_THRESHOLD_MIDDLE,
  DEFAULT_SLIDE_DETECTION_THRESHOLD_HIGH,
} from "../Store/SlideDBTemplate"
import { checkSlideMedia } from "./SlideMediaChecker"
import { openFileManager } from "../Constants/UrlConfig"

import CloseButton from "../Common/CloseButton"
import { HMFileIO, getUrlDisplayFormat } from "../Common/HMFileIO"

import ReactTooltip from "react-tooltip"
import { ImPaste } from "react-icons/im"
import FallbackImg from "./404.svg"
import {
  getShortenedLabel,
  isIndex,
  isUrl,
  parseClipboard,
  moveInArray,
} from "../Common/utils"
import { GlobalContext } from "../Store/ContentStore"
import { HiOutlineQuestionMarkCircle } from "react-icons/hi"
import {
  MdOutlineSignalCellularAlt,
  MdOutlineSignalCellularAlt2Bar,
  MdOutlineSignalCellularAlt1Bar,
} from "react-icons/md"

const OpacitySlider = Slider.createSliderWithTooltip(Slider)
/*const ScaleSlider = Slider.createSliderWithTooltip(Slider)*/

const DISPLAY_DEBUGGER = false
const LOCK_MEDIA_FEATURE = false
const STEP_DESCRIPTION_HEIGHT = 130 // auto(default) : 62

const PropertyEditor = ({
  id,
  compositing,
  disabled,
  opacity,
  scale,
  onChange,
}) => {
  const [sliderOpacity, setSliderOpacity] = useState(opacity)
  const [inputScale, setInputScale] = useState(Math.floor(scale))

  useEffect(() => {
    setSliderOpacity(opacity)
  }, [opacity])

  useEffect(() => {
    if (inputScale && inputScale > 0 && inputScale <= 200) {
      onChange({ target: { name: "scale", value: inputScale } }, id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputScale])

  /*
   * LEGACY (scale slider) - for possible rollback
   *  useEffect(() => {
   *    setSliderScale(scale)
   *  }, [scale])
   */
  useEffect(() => {
    setInputScale(Math.floor(scale))
  }, [scale])

  return (
    <div className="form-row">
      <div className="form-group col-sm-3">
        <label className="col-form-label font-weight-bold">Compositing</label>
        <select
          value={compositing}
          className="form-control"
          name="compositing"
          onChange={(e) => onChange(e, id)}
          style={{ cursor: disabled ? "not-allowed" : "pointer" }}
          disabled={disabled}
        >
          {_compositing.map((comp) => {
            return (
              <option value={comp} key={comp}>
                {comp}
              </option>
            )
          })}
        </select>
      </div>
      <div className="form-group col-sm-2 scale-input-container">
        <label className="col-form-label font-weight-bold">Scale</label>
        <input
          type="number"
          /*step={10}*/
          value={inputScale.toString()}
          onChange={(e) => {
            let value = e.target.value // is string
            if (value.length && value.length > 1 && value[0] === "0") {
              value = value.substring(1)
            }
            const final = Math.max(0, Math.min(Math.floor(Number(value)), 200))
            setInputScale(final)
          }}
        />
        <span className="input-percent-indicator">%</span>
        {/* LEGACY (scale slider) - for possible rollback
          * <ScaleSlider
            min={1}
            max={100}
            step={1}
            disabled={disabled}
            value={sliderScale}
            tipFormatter={(value) => value + "%"}
            onChange={setSliderScale}
            onAfterChange={() =>
              onChange({ target: { name: "scale", value: sliderScale } }, id)
            }
          />
         */}
      </div>
      <div
        className="form-group col-sm-2"
        style={{ marginRight: "15px", marginLeft: "15px" }}
      >
        <label className="col-form-label font-weight-bold">Opacity</label>
        <OpacitySlider
          min={1}
          max={100}
          step={1}
          disabled={disabled}
          value={sliderOpacity}
          tipFormatter={(value) => value + "%"}
          onChange={setSliderOpacity}
          onAfterChange={() =>
            onChange({ target: { name: "opacity", value: sliderOpacity } }, id)
          }
        />
      </div>
    </div>
  )
}

const FormMedia = ({
  id,
  openHMKComposer,
  picture_id,
  layer_id,
  part_url,
  compositing,
  opacity,
  scale,
  displayDelete,
  onClick,
  onChange,
  onClearLink,
  onCopyLink,
  onPasteLink,
  onDeleteMedia,
  onMoveMediaUp,
  onLockMedia,
  onResetMedia,
  selected,
  locked,
  scrollOffset,
}) => {
  const [imgValidity, setImgValidity] = useState(true)
  const [highlightImage, setHighlightImage] = useState(false)
  const inputRef = useRef(null)

  const lockThisMedia = (e) => {
    if (LOCK_MEDIA_FEATURE) {
      e.stopPropagation()
      onLockMedia()
    }
  }

  useEffect(() => {
    let mounted = true
    if (part_url) {
      HMFileIO().imageValidityCheck(part_url).then((status) => {
        !status && mounted && setImgValidity(false)
      })
    }
    return () => {
      mounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [part_url])

  const edited = useMemo(() => {
    return Boolean(
      scale !== DEFAULT_MEDIA_SCALE ||
        opacity !== DEFAULT_MEDIA_OPACITY ||
        compositing !== _compositing[0]
    )
  }, [scale, opacity, compositing])

  return (
    <>
      <div
        className={`form-media ${selected ? "selected" : ""}`}
        onClick={() => onClick(id)}
      >
        <div
          className={`form-media-thumbnail-container ${
            part_url ? "" : "form-media-thumbnail-disabled"
          }`}
        >
          {part_url && (
            <>
              <img
                src={imgValidity ? part_url : FallbackImg}
                alt={picture_id}
                style={
                  !imgValidity
                    ? {
                        width: "100px",
                        height: "100px",
                      }
                    : {}
                }
                onMouseEnter={() => setHighlightImage(true)}
                onMouseLeave={() => setHighlightImage(false)}
                onClick={lockThisMedia}
              />
              <ReactTooltip
                id={`lock-media-${picture_id}${id}`}
                scrollHide={false}
                offset={{
                  top: -scrollOffset,
                }}
              />
            </>
          )}
          {LOCK_MEDIA_FEATURE && (
            <div
              className={`form-media-lock-icon ${
                highlightImage || selected ? "hovering" : ""
              }`}
              data-tip={locked ? "Unlock" : "Lock"}
              data-for={`lock-media-${picture_id}${id}`}
              onClick={lockThisMedia}
            >
              {locked ? <FaLock /> : <FaLockOpen />}
            </div>
          )}
          {DISPLAY_DEBUGGER && (
            <span className="debugger-layer-id">
              L_ID : {layer_id}, IDX: {id}
            </span>
          )}
        </div>
        <div className="form-media-container">
          <label className="form-media-label">Link</label>
          <div className="form-media-link-wrapper">
            <div
              className="form-media-link"
              data-tip
              data-for={`input-field-${picture_id}${id}`}
            >
              <input
                ref={inputRef}
                value={getShortenedLabel(getUrlDisplayFormat(part_url), 40)}
                readOnly={true}
                type="text"
                style={{
                  color: imgValidity ? "inherit" : "#FA8289",
                }}
                name="part_url"
                onChange={(e) => onChange(e, id)}
                onClick={(e) => {
                  e.stopPropagation()
                  inputRef.current.blur()
                  !imgValidity && displayDelete
                    ? onDeleteMedia(part_url)
                    : !imgValidity && !displayDelete
                    ? onClearLink(id)
                    : !part_url
                    ? onPasteLink(id)
                    : onCopyLink()
                }}
              />
              {!imgValidity && displayDelete ? (
                <FiTrash2 className="input-deco-button" />
              ) : !imgValidity && !displayDelete ? (
                <MdClose className="input-deco-button" />
              ) : !part_url ? (
                <ImPaste className="input-deco-button" />
              ) : (
                <FiLink className="input-deco-button" />
              )}
              <ReactTooltip
                id={`input-field-${picture_id}${id}`}
                scrollHide={false}
                offset={{
                  top: -scrollOffset,
                }}
              >
                <span>
                  {!imgValidity && displayDelete
                    ? "Delete corrupted link"
                    : !imgValidity && !displayDelete
                    ? "Clear Link"
                    : !part_url
                    ? "Paste Link"
                    : "Copy Link"}
                </span>
              </ReactTooltip>
            </div>
            <div className="form-media-first-row">
              {part_url && !(!imgValidity && !displayDelete) && (
                <div className="card-action">
                  <MdClose
                    className="card-action-icon"
                    data-tip="Clear Link"
                    data-for={`rtt-clear-link-${picture_id}${id}`}
                    onClick={() => onClearLink(id)}
                  />
                  <ReactTooltip
                    id={`rtt-clear-link-${picture_id}${id}`}
                    effect="solid"
                    place="top"
                    offset={{
                      top: -scrollOffset,
                    }}
                  />
                </div>
              )}
              {imgValidity && picture_id && part_url && (
                <label className="card-action">
                  <TiPencil
                    className="card-action-icon"
                    data-tip="Composer"
                    data-for={`rtt-open-composer-${picture_id}${id}`}
                    onClick={() => openHMKComposer(picture_id)}
                  />
                  <ReactTooltip
                    id={`rtt-open-composer-${picture_id}${id}`}
                    effect="solid"
                    place="top"
                    offset={{
                      top: -scrollOffset,
                    }}
                  />
                </label>
              )}
              {edited && imgValidity && (
                <label className="card-action">
                  <FaUndo
                    className="card-action-icon smaller"
                    data-tip="Reset"
                    data-for={`rtt-reset-media-${picture_id}${id}`}
                    onClick={(e) => {
                      e.stopPropagation()
                      onResetMedia()
                    }}
                  />
                  <ReactTooltip
                    id={`rtt-reset-media-${picture_id}${id}`}
                    effect="solid"
                    place="top"
                    offset={{
                      top: -scrollOffset,
                    }}
                  />
                </label>
              )}
              {displayDelete && (
                <label className="card-action">
                  <FiTrash2
                    className="card-action-icon"
                    data-tip="Delete"
                    data-for={`rtt-delete-media-${picture_id}${id}`}
                    onClick={() => onDeleteMedia(part_url)}
                  />
                  <ReactTooltip
                    id={`rtt-delete-media-${picture_id}${id}`}
                    effect="solid"
                    place="top"
                    offset={{
                      top: -scrollOffset,
                    }}
                  />
                </label>
              )}
              {id > 0 && (
                <label className="card-action">
                  <FaArrowUp
                    className="card-action-icon"
                    data-tip="Move Up"
                    data-for={`rtt-lift-media-${picture_id}${id}`}
                    onClick={(e) => {
                      e.stopPropagation()
                      onMoveMediaUp(id)
                    }}
                  />
                  <ReactTooltip
                    id={`rtt-lift-media-${picture_id}${id}`}
                    effect="solid"
                    place="top"
                    offset={{
                      top: -scrollOffset,
                    }}
                  />
                </label>
              )}
            </div>
          </div>
          <PropertyEditor
            id={id}
            compositing={compositing}
            opacity={opacity}
            scale={scale}
            onChange={onChange}
            disabled={Boolean(!imgValidity || !part_url)}
          />
        </div>
      </div>
      <hr style={{ border: ".5px solid #fff" }} />
    </>
  )
}

const SlideContentEditor = (props) => {
  // cached local variable (required because it's expecting the user to modify items.)
  // if selected media were uuid, it would be more stable.
  // but since working with index is highly mutanble, need to work with cache.
  const [selectedMediaIndex, setSelectedMediaIndex] = useState(
    props.selectedMediaIndex
  )
  const [scrollOffset, setScrollOffset] = useState(0)
  const [heightCache, setHeightCache] = useState(0)
  // eslint-disable-next-line no-unused-vars
  const [cache, _unused] = useState(JSON.stringify(props.item))
  const scrollRef = useRef(null)
  useEffect(() => {
    props.dispatch({
      type: "SET_SELECTED_MEDIA_INDEX",
      payload: selectedMediaIndex,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMediaIndex])

  const onClose = () => {
    const recovered_cache = JSON.parse(cache)
    props.dispatch({ type: "UPDATE_SLIDE", payload: recovered_cache })
    props.dispatch({ type: "VIEW_MODE", payload: "" })
  }
  const onSubmit = (item) => {
    // check contents part urls set
    // and retrieve corresponding picture id from DB
    // and set contents angle data for each picture if relevant
    const uid = props.firebase.auth.currentUser.uid
    checkSlideMedia(uid, item)
      .then(() => {
        props.dispatch({ type: "UPDATE_SLIDE", payload: item })
      })
      .catch((error) => {
        alert("Problem when checking URL: " + error)
      })
      .finally(() => {
        props.refreshDetail()
        props.dispatch({ type: "VIEW_MODE", payload: "" })
      })
  }

  return (
    <div
      className="slide-editor-backdrop"
      id="backdrop"
      onMouseDown={(e) => {
        if (e.target.id === "backdrop") {
          e.stopPropagation()
          onClose()
        }
      }}
    >
      <div
        className="slide_template_editor"
        ref={scrollRef}
        onScroll={() => {
          if (!scrollRef.current) return
          if (!heightCache) {
            setHeightCache(scrollRef.current.scrollHeight)
            scrollRef.current.scrollTop = 0
          }
          setScrollOffset(scrollRef.current.scrollTop)
        }}
      >
        <CloseButton onClick={onClose} />
        <h2 className="text-center">Edit Slide</h2>
        <Content
          {...props}
          selectedMediaIndex={selectedMediaIndex}
          setSelectedMediaIndex={setSelectedMediaIndex}
          onSubmit={onSubmit}
          onClose={onClose}
          scrollOffset={scrollOffset}
          cache={cache}
        />
      </div>
    </div>
  )
}

const ChecklistItem = ({
  text,
  checked,
  id,
  idx,
  onUp,
  onDown,
  deleteCallback,
  confirmCallback,
}) => {
  const [cache, setCache] = useState(text)
  const [currentText, setText] = useState(text)
  const checkboxRef = useRef(null)

  useEffect(() => {
    if (checkboxRef && checkboxRef.current) {
      checkboxRef.current.checked = checked
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked])

  const confirm = () => {
    confirmCallback({
      id: id,
      checked: checkboxRef.current.checked,
      text: currentText,
    })
  }

  return (
    <div
      className="scedit-checklist-item"
      style={{ backgroundColor: idx % 2 > 0 ? "#3d3d3d" : "#333333" }}
    >
      <span>{idx + 1}.</span>
      <input ref={checkboxRef} type={"checkbox"} onChange={() => confirm()} />
      <input
        value={currentText}
        onChange={(e) => {
          setText(e.target.value)
        }}
        onBlur={() => {
          setText(cache)
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            if (currentText !== cache) {
              confirm()
              setCache(currentText)
            } else {
              e.target.blur()
            }
          }
        }}
      />
      {currentText !== cache ? (
        <>
          <div
            className="scedit-link-btn scedit-link-edit-btn"
            onMouseDown={() => {
              setText(cache)
            }}
          >
            <FaUndo />
          </div>
          <div
            className="scedit-link-btn scedit-link-edit-btn"
            onMouseDown={() => {
              confirm()
              setCache(currentText)
            }}
          >
            <FaCheck />
          </div>
        </>
      ) : (
        <></>
      )}
      {onUp ? (
        <div
          className="scedit-link-btn scedit-link-edit-btn"
          onMouseDown={() => onUp()}
        >
          <FaArrowUp />
        </div>
      ) : (
        <></>
      )}
      {onDown ? (
        <div
          className="scedit-link-btn scedit-link-edit-btn"
          onMouseDown={() => onDown()}
        >
          <FaArrowDown />
        </div>
      ) : (
        <></>
      )}
      <div
        className="scedit-link-btn scedit-link-remove-btn"
        onClick={() => deleteCallback()}
      >
        <FaMinus />
      </div>
    </div>
  )
}

const EditUserContent = (props) => {
  const globalContext = useContext(GlobalContext)
  /** In item, there are an array of content (named content), counter (the slide counter), options (what to display from template), part_picked (the part picked on this slide -useless) and seq_id */
  const [item, setItem] = useState(props.item)
  const [slideEdited, setSlideEdited] = useState(false)
  const [url, setURL] = useState("")
  const [urlName, setURLName] = useState("")
  const [urlValidity, setUrlValidity] = useState(false)
  const [checklistItem, setChecklistItem] = useState("")

  useEffect(() => {
    if (!urlName || !urlName.trim() || url.length <= 6 || !url.includes(".")) {
      setUrlValidity(false)
      return
    }
    setUrlValidity(isUrl(url))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, url, urlName])

  useEffect(() => {
    setItem(props.item)
  }, [props.item])

  useEffect(() => {
    const onKeyDown = (event) => {
      //console.log(`OnKeyDown called on form, key is:`, event.keyCode)
      if (event.keyCode === 13) {
        // Enter key : valid the form
        if (event.target.tagName === "INPUT") {
          event.preventDefault()
          event.stopPropagation()
          return
        }
        if (event.target.tagName === "TEXTAREA") {
          return
        }
        props.onSubmit(item)
        event.stopPropagation()
      } else if (event.keyCode === 27) {
        // Esc key : clode the form, no save
        props.onClose()
        event.stopPropagation()
      }
    }
    document.addEventListener("keydown", onKeyDown)
    return () => {
      document.removeEventListener("keydown", onKeyDown)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, props.onSubmit])

  useEffect(() => {
    if (slideEdited) {
      props.dispatch({ type: "UPDATE_SLIDE", payload: item })
      setSlideEdited(false)
    } else {
      const newItem = JSON.stringify(item)
      if (props.cache !== newItem) {
        setSlideEdited(true)
        //props.dispatch({ type: "UPDATE_SLIDE", payload: item })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cache, item])

  const onChange = (event, id) => {
    const n = event.target.name
    let v = event.target.value
    if (n === "opacity") {
      const result = v
      if (result < 0 || 100 < result) return
      setItem((prev) => ({
        ...prev,
        content: [
          ...prev.content.slice(0, id),
          {
            ...prev.content[id],
            opacity: result,
          },
          ...prev.content.slice(id + 1),
        ],
      }))
    } else if (n === "compositing") {
      setItem((prev) => ({
        ...prev,
        content: [
          ...prev.content.slice(0, id),
          {
            ...prev.content[id],
            compositing: v,
          },
          ...prev.content.slice(id + 1),
        ],
      }))
    } else if (n === "scale") {
      const new_scale = v
      if (new_scale <= 0) return
      const new_scale_coeff = v / 100
      const prev_scale = item.content[id].scale
      const delta_scale = v - prev_scale // shrink if negative & expand if positive
      if (!delta_scale) return
      const prev_scale_coeff = prev_scale / 100
      const prev_X = item.content[id].x
      const prev_Y = item.content[id].y
      const media_half_width = item.content[id].width / 2
      const media_half_height = item.content[id].height / 2
      const center_x = prev_X + media_half_width * prev_scale_coeff
      const center_y = prev_Y + media_half_height * prev_scale_coeff
      const offset_x = new_scale_coeff * media_half_width
      const offset_y = new_scale_coeff * media_half_height
      // Recalculate the center point
      setItem((prev) => ({
        ...prev,
        content: [
          ...prev.content.slice(0, id),
          {
            ...prev.content[id],
            scale: new_scale,
            x: center_x - offset_x,
            y: center_y - offset_y,
          },
          ...prev.content.slice(id + 1),
        ],
      }))
      /** Handling numeric fields */
    } else if (
      n === "delay" ||
      n === "detection_threshold_low" ||
      n === "detection_threshold_middle" ||
      n === "detection_threshold_high"
    ) {
      let valueAsNumber = event.target.valueAsNumber
      if (n === "delay") {
        valueAsNumber = Math.max(-1, valueAsNumber)
      } else if (n === "detection_threshold_low") {
        valueAsNumber = Math.max(
          1,
          Math.min(
            typeof item.detection_threshold_middle === "number"
              ? item.detection_threshold_middle - 1
              : DEFAULT_SLIDE_DETECTION_THRESHOLD_MIDDLE - 1,
            valueAsNumber
          )
        )
      } else if (n === "detection_threshold_middle") {
        valueAsNumber = Math.max(
          typeof item.detection_threshold_low === "number"
            ? item.detection_threshold_low
            : DEFAULT_SLIDE_DETECTION_THRESHOLD_MIDDLE,
          Math.min(
            typeof item.detection_threshold_high === "number"
              ? item.detection_threshold_high - 1
              : DEFAULT_SLIDE_DETECTION_THRESHOLD_HIGH - 1,
            valueAsNumber
          )
        )
      } else if (n === "detection_threshold_high") {
        valueAsNumber = Math.max(
          typeof item.detection_threshold_low === "number"
            ? item.detection_threshold_low
            : DEFAULT_SLIDE_DETECTION_THRESHOLD_HIGH,
          Math.min(99, valueAsNumber)
        )
      }
      setItem((prev) => ({
        ...prev,
        [n]: valueAsNumber,
      }))
      /** Handling boolean fields */
    } else if (n === "video") {
      setItem((prev) => ({
        ...prev,
        video: Boolean(v === "true" || v === true),
      }))
      /** when detection mode changes, reset its dependant fields */
    } else if (n === "detection_mode") {
      const new_item = { ...item }
      if (item.detection_mode !== v) {
        new_item.detection_mode = v
        new_item.detection_model = ""
        new_item.detection_labels = ""
      }
      setItem(new_item)
      /** when blocking mode changes, reset its dependant fields */
    } else if (n === "blocking_mode") {
      const new_item = { ...item }
      if (item.blocking_mode !== v) {
        new_item.blocking_mode = v
        new_item.blocking_labels = ""
      }
      setItem(new_item)
      /** Handling string fields */
    } else if (
      n === "detection_model" ||
      n === "detection_labels" ||
      n === "blocking_labels"
    ) {
      setItem((prev) => ({
        ...prev,
        [n]: v,
      }))
    } else if (id === 0 || id > 0) {
      setItem((prev) => ({
        ...prev,
        content: [
          ...item.content.slice(0, id),
          { ...item.content[id], [n]: v },
          ...item.content.slice(id + 1),
        ],
      }))
    } else {
      const newContent = item.content.map((c) => ({ ...c, [n]: v }))
      setItem((prev) => ({ ...prev, content: newContent }))
    }
  }

  const handleSubmit = (event) => {
    props.onSubmit(item)
    event.preventDefault()
  }

  const onCopyLink = (content) => {
    if (!content.part_url) return
    const obj = JSON.stringify({ ...content })
    navigator.clipboard.writeText(obj)
    globalContext.displaySnackbar({
      message: [
        "Copied content : ",
        getShortenedLabel(getUrlDisplayFormat(content.part_url), 25),
      ],
    })
  }

  const onResetMedia = (content, idx) => {
    setItem((prev) => ({
      ...prev,
      content: [
        ...prev.content.slice(0, idx),
        {
          ...prev.content[idx],
          opacity: DEFAULT_MEDIA_OPACITY,
          scale: DEFAULT_MEDIA_SCALE,
          x: 0,
          y: 0,
          compositing: _compositing[0],
          width: props.viewBox[2],
          height: props.viewBox[3],
        },
        ...prev.content.slice(idx + 1),
      ],
    }))
  }

  const onPasteLink = async (id) => {
    const text = await navigator.clipboard.readText()
    const as_content = parseClipboard(text)
    const is_only_url = isUrl(text)
    if (as_content.part_url || is_only_url) {
      const w = props.viewBox[2] // *DEFAULT_MEDIA_SIZE_PERCENT/ 100
      const h = props.viewBox[3] // *DEFAULT_MEDIA_SIZE_PERCENT/ 100
      const x = (props.viewBox[2] - w) / 2
      const y = (props.viewBox[3] - h) / 2

      const new_part_url =
        is_only_url || !as_content.part_url ? text : as_content.part_url
      const new_opacity =
        is_only_url && item.content[id].opacity
          ? item.content[id].opacity
          : !as_content.opacity
          ? DEFAULT_MEDIA_OPACITY
          : as_content.opacity
      const new_compositing =
        is_only_url && item.content[id].compositing
          ? item.content[id].compositing
          : !as_content.compositing
          ? _compositing[0]
          : as_content.compositing
      const new_scale =
        is_only_url && item.content[id].scale
          ? item.content[id].scale
          : !as_content.scale
          ? DEFAULT_MEDIA_SCALE
          : as_content.scale
      const new_part_picture_id = as_content.part_picture_id
        ? as_content.part_picture_id
        : ""
      const new_compo_url = as_content.compo_url ? as_content.compo_url : ""
      const new_width =
        is_only_url && item.content[id].width
          ? item.content[id].width
          : !as_content.width
          ? w
          : as_content.width
      const new_height =
        is_only_url && item.content[id].height
          ? item.content[id].height
          : !as_content.height
          ? h
          : as_content.height
      const new_x =
        is_only_url && item.content[id].x
          ? item.content[id].x
          : !as_content.x
          ? x
          : as_content.x
      const new_y =
        is_only_url && item.content[id].y
          ? item.content[id].y
          : !as_content.y
          ? y
          : as_content.y
      const new_angle = is_only_url || !as_content.angle ? 0 : as_content.angle
      const new_content = {
        ...item.content[id],
        part_url: new_part_url,
        part_picture_id: new_part_picture_id,
        compo_url: new_compo_url,
        compositing: new_compositing,
        opacity: new_opacity,
        scale: new_scale,
        width: new_width,
        height: new_height,
        x: new_x,
        y: new_y,
        angle: new_angle,
      }
      setItem((prev) => ({
        ...prev,
        content: [
          ...prev.content.slice(0, id),
          new_content,
          ...prev.content.slice(id + 1),
        ],
      }))
    } else {
      alert("Text from clipboard isn't a valid URL")
    }
  }

  const onClearLink = (id) => {
    setItem((prev) => ({
      ...prev,
      content: [
        ...prev.content.slice(0, id),
        {
          ...prev.content[id],
          part_url: "",
          compo_url: "",
          part_picture_id: "",
        },
        ...prev.content.slice(id + 1),
      ],
    }))
  }

  const triggerDeleteMedia = (idx) => {
    props.dispatch({
      type: "REMOVE_SLIDE_CONTENT",
      payload: { seq_id: item.seq_id, index: idx },
    })
    props.refreshDetail()
  }

  const onDeleteMedia = (idx) => {
    props.openModal({
      title: "Removing image",
      message: "Are you sure you want to remove this image ?",
      onSubmit: () => {
        triggerDeleteMedia(idx)
      },
    })
  }

  const onMoveMediaUp = (id) => {
    const newContent = item.content
      .slice(0, id - 1)
      .concat(
        [item.content[id]],
        [item.content[id - 1]],
        item.content.slice(id + 1)
      )
    setItem((prev) => ({
      ...prev,
      content: newContent,
    }))
  }

  const onAddMedia = (event) => {
    event.preventDefault()
    event.stopPropagation()
    let newContent = { ...item.content[0] }
    newContent.part_url = ""
    newContent.part_picture_id = ""
    newContent.compo_url = ""
    newContent.compositing = "NORMAL"
    newContent.opacity = DEFAULT_MEDIA_OPACITY
    newContent.scale = DEFAULT_MEDIA_SCALE
    newContent.angle = 0
    newContent.x = 0
    newContent.y = 0
    newContent.width = props.viewBox[2]
    newContent.height = props.viewBox[3]
    // delete newContent.width <= FIXED 11/03/2022 : width cannot be NaN
    // delete newContent.height <= FIXED 11/03/2022 : height cannot be NaN
    let layerId =
      item.content.reduce(
        (result, item) => (item.layer_id > result ? item.layer_id : result),
        0
      ) + 1
    newContent.layer_id = layerId
    setItem((prev) => ({ ...prev, content: [newContent].concat(prev.content) }))
    if (isIndex(props.selectedMediaIndex)) {
      props.setSelectedMediaIndex((prev) => prev + 1)
    }
  }

  const openHMKComposer = async (pictureId) => {
    if (
      props.sequence.currentProject &&
      props.sequence.currentProject.folder_id &&
      pictureId
    )
      openFileManager(
        props.sequence.currentProject.folder_id,
        await props.firebase.doGetIdToken(),
        "&composer=" + pictureId
      )
  }

  /**
   * @param {"video" | "audio"} media_type
   */
  const onPasteMediaClip = async (media_type) => {
    const text = await navigator.clipboard.readText()
    const as_content = parseClipboard(text)
    const is_only_url = isUrl(text)
    const url = is_only_url || !as_content.part_url ? text : as_content.part_url
    let key = media_type === "video" ? "video_clip" : "audio_clip"
    setItem((prev) => ({
      ...prev,
      [key]: url,
    }))
  }

  /**
   * @param {"video" | "audio"} media_type
   */
  const onClearMediaClip = (media_type) => {
    let key = media_type === "video" ? "video_clip" : "audio_clip"
    setItem((prev) => ({
      ...prev,
      [key]: "",
    }))
  }

  const updateChecklistItem = (idx, payload) => {
    if (item.checklist.length - 1 < idx || !payload) return
    setItem((prev) => {
      let new_checklist = [...prev.checklist]
      new_checklist[idx] = payload
      return {
        ...prev,
        checklist: new_checklist,
      }
    })
  }

  const registerChecklistItem = () => {
    if (!checklistItem.trim()) return
    setChecklistItem("")
    const newItem = { id: Date.now(), checked: false, text: checklistItem }
    setItem((prev) => ({
      ...prev,
      checklist: prev.checklist ? [...prev.checklist, newItem] : [newItem],
    }))
  }

  const moveChecklistItem = (direction, idx) => {
    if (!item.checklist || !item.checklist.length) return
    const new_checklist = moveInArray(
      [...item.checklist],
      idx,
      direction === "up" ? idx - 1 : idx + 1
    )
    setItem((prev) => ({
      ...prev,
      checklist: new_checklist,
    }))
  }

  const deleteChecklistItem = (id) => {
    if (!item.checklist || !item.checklist.length) return
    setItem((prev) => ({
      ...prev,
      checklist: prev.checklist.filter((cl) => cl.id !== id),
    }))
  }

  const registerURL = () => {
    setURL("")
    setURLName("")
    const _urlName = urlName.trim()
    const newItem = { name: _urlName, url: url }
    if (!item.links || !item.links.length) {
      setItem((prev) => ({
        ...prev,
        links: [newItem],
      }))
      return
    }
    let dup_index = -1
    let new_links = [...item.links]
    for (let i = 0; i < item.links.length; i++) {
      if (item.links[i].name === _urlName || item.links[i].url === url) {
        dup_index = i
        break
      }
    }
    if (dup_index < 0) {
      setItem((prev) => ({
        ...prev,
        links: [...prev.links, newItem],
      }))
    } else {
      new_links[dup_index] = newItem
      setItem((prev) => ({
        ...prev,
        links: new_links,
      }))
    }
  }

  const deleteURL = (url_name) => {
    if (!item.links || !item.links.length) return
    setItem((prev) => ({
      ...prev,
      links: prev.links.filter((l) => l.name !== url_name),
    }))
  }

  return (
    <AuthUserContext.Consumer>
      {() => (
        <form onSubmit={handleSubmit} key={item.seq_id}>
          <div className="scedit-row" style={{ gap: "1rem" }}>
            <div style={{ flex: 1 }}>
              <label className="col-form-label font-weight-bold">
                Step Name*
              </label>
              <input
                required
                defaultValue={item.content[0].part_name}
                type="text"
                className="form-control"
                name="part_name"
                onChange={onChange}
              />
            </div>
            <div>
              <label className="col-form-label font-weight-bold">
                Number of
              </label>
              <input
                defaultValue={item.content[0].part_pick_count}
                type="number"
                style={{ width: "125px" }}
                className="form-control"
                name="part_pick_count"
                onChange={onChange}
              />
            </div>
            <div>
              <label className="col-form-label font-weight-bold">Delay</label>
              <input
                value={item.delay && item.delay >= 1 ? item.delay : ""}
                type="number"
                style={{ width: "125px" }}
                className="form-control"
                name="delay"
                onChange={onChange}
              />
            </div>
            <div>
              <label className="col-form-label font-weight-bold">Video</label>
              <select
                value={item.video}
                className="form-control"
                name="video"
                style={{ width: "125px" }}
                onChange={onChange}
              >
                <option value={true}>On</option>
                <option value={false}>Off</option>
              </select>
            </div>
          </div>
          <div className="scedit-row">
            <div style={{ flex: 1 }}>
              <label className="col-form-label font-weight-bold">
                Step description
              </label>
              <textarea
                defaultValue={item.content[0].part_pick_name}
                rows="2"
                style={{
                  height: STEP_DESCRIPTION_HEIGHT,
                  background: "#1e1e1e",
                  color: "#ffffff",
                }}
                className="form-control"
                name="part_pick_name"
                onChange={onChange}
              />
            </div>
            <div
              className="scedit-checklist-wrapper"
              style={{ height: 38 + STEP_DESCRIPTION_HEIGHT }}
            >
              <label className="col-form-label font-weight-bold">
                Check list
              </label>
              <div className="scedit-checklist-container">
                <div className="scedit-checklist-input-container">
                  <input
                    value={checklistItem}
                    onChange={(e) => {
                      setChecklistItem(e.target.value)
                    }}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13 && checklistItem) {
                        registerChecklistItem()
                      }
                    }}
                    className="scedit-checklist-input"
                    placeholder="Check List Item"
                  />
                  <div
                    className={`scedit-link-undo-btn ${
                      checklistItem ? "scedit-btn-highlight" : ""
                    }`}
                    style={{
                      cursor: checklistItem ? "pointer" : "not-allowed",
                    }}
                    onClick={() => setChecklistItem("")}
                  >
                    <FaUndo color={checklistItem ? "" : "#1e1e1e"} />
                  </div>
                  <div
                    className={`scedit-link-confirm-btn ${
                      checklistItem ? "scedit-btn-highlight" : ""
                    }`}
                    style={{
                      cursor: checklistItem ? "pointer" : "not-allowed",
                    }}
                    onClick={() => {
                      if (!checklistItem) return
                      registerURL()
                    }}
                  >
                    <FaPlus color={checklistItem ? "" : "#1e1e1e"} />
                  </div>
                </div>
                <div
                  className="scedit-checklist-box"
                  style={{
                    minHeight: STEP_DESCRIPTION_HEIGHT - 38,
                    maxHeight: STEP_DESCRIPTION_HEIGHT - 38,
                  }}
                >
                  {item.checklist &&
                    item.checklist.map((cl, idx) => {
                      return (
                        <ChecklistItem
                          key={cl.id}
                          text={cl.text}
                          checked={cl.checked}
                          id={cl.id}
                          idx={idx}
                          onUp={
                            idx !== 0 &&
                            (() => {
                              moveChecklistItem("up", idx)
                            })
                          }
                          onDown={
                            item.checklist.length !== 1 &&
                            idx !== item.checklist.length - 1 &&
                            (() => {
                              moveChecklistItem("down", idx)
                            })
                          }
                          confirmCallback={(payload) => {
                            updateChecklistItem(idx, payload)
                          }}
                          deleteCallback={() => {
                            deleteChecklistItem(idx)
                          }}
                        />
                      )
                    })}
                </div>
              </div>
            </div>
          </div>
          <div className="scedit-row">
            <div
              className="scedit-link-wrapper"
              style={{ height: 38 + STEP_DESCRIPTION_HEIGHT }}
            >
              <label className="col-form-label font-weight-bold">Links</label>
              <div className="scedit-links-container">
                <div className="scedit-link-input-container">
                  <input
                    value={urlName}
                    onChange={(e) => {
                      setURLName(e.target.value)
                    }}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13 && urlValidity) {
                        registerURL()
                      }
                    }}
                    className="scedit-link-input"
                    type="url"
                    placeholder="Link Name"
                    pattern="https://.*"
                  />
                  <input
                    value={url}
                    onChange={(e) => {
                      let val = e.target.value
                      if (url.length < val.length && val.length === 1) {
                        val = "https://" + val
                      }
                      setURL(val)
                    }}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13 && urlValidity) {
                        registerURL()
                      }
                    }}
                    className="scedit-link-input"
                    type="url"
                    placeholder="https://example.com"
                    pattern="https://.*"
                  />
                  <div
                    className={`scedit-link-undo-btn ${
                      url || urlName ? "scedit-btn-highlight" : ""
                    }`}
                    style={{
                      cursor: url || urlName ? "pointer" : "not-allowed",
                    }}
                    onClick={() => {
                      setURL("")
                      setURLName("")
                    }}
                  >
                    <FaUndo color={urlValidity ? "" : "#1e1e1e"} />
                  </div>
                  <div
                    className={`scedit-link-confirm-btn ${
                      urlValidity ? "scedit-btn-highlight" : ""
                    }`}
                    style={{
                      cursor: urlValidity ? "pointer" : "not-allowed",
                    }}
                    onClick={() => {
                      if (!urlValidity) return
                      registerURL()
                    }}
                  >
                    <FaPlus color={urlValidity ? "" : "#1e1e1e"} />
                  </div>
                </div>
                <div
                  className="scedit-links-box"
                  style={{
                    minHeight: STEP_DESCRIPTION_HEIGHT - 38,
                    maxHeight: STEP_DESCRIPTION_HEIGHT - 38,
                  }}
                >
                  {item.links &&
                    item.links.map((link, idx) => {
                      return (
                        <div className="scedit-link" key={idx}>
                          <span>
                            {link.name} ({getShortenedLabel(link.url, 40)})
                          </span>
                          <div
                            className="scedit-link-btn scedit-link-edit-btn"
                            onClick={() => {
                              setURL(link.url)
                              setURLName(link.name)
                            }}
                          >
                            <FaEdit />
                          </div>
                          <div
                            className="scedit-link-btn scedit-link-remove-btn"
                            onClick={() => {
                              deleteURL(link.name)
                            }}
                          >
                            <FaMinus />
                          </div>
                        </div>
                      )
                    })}
                </div>
              </div>
            </div>
          </div>
          <hr style={{ border: ".5px solid #fff" }} />
          <div
            className="scedit-link-wrapper"
            style={{ height: STEP_DESCRIPTION_HEIGHT - 38 }}
          >
            <label className="col-form-label font-weight-bold">
              Video Clip
            </label>
            <div className="form-media-link-wrapper">
              <div
                className="form-media-link"
                data-tip
                data-for="input-field-video-clip"
              >
                <input
                  value={getShortenedLabel(
                    getUrlDisplayFormat(item.video_clip),
                    40
                  )}
                  readOnly={true}
                  type="text"
                  name="video_clip"
                  onClick={(e) => {
                    e.stopPropagation()
                    !item.video_clip
                      ? onPasteMediaClip("video")
                      : onClearMediaClip("video")
                  }}
                />
                {!item.video_clip ? (
                  <ImPaste className="input-deco-button" />
                ) : (
                  <MdClose className="input-deco-button" />
                )}
                <ReactTooltip
                  id="input-field-video-clip"
                  scrollHide={false}
                  offset={{
                    top: -props.scrollOffset,
                  }}
                >
                  <span>
                      {!item.video_clip
                      ? "Paste Link"
                      : "Clear Link"}
                  </span>
                </ReactTooltip>
              </div>
            </div>
          </div>
          <div
            className="scedit-link-wrapper"
            style={{ height: STEP_DESCRIPTION_HEIGHT - 38 }}
          >
            <label className="col-form-label font-weight-bold">
              Audio Clip
            </label>
            <div className="form-media-link-wrapper">
              <div
                className="form-media-link"
                data-tip
                data-for="input-field-audio-clip"
              >
                <input
                  value={getShortenedLabel(
                    getUrlDisplayFormat(item.audio_clip),
                    40
                  )}
                  readOnly={true}
                  type="text"
                  name="audio_clip"
                  onClick={(e) => {
                    e.stopPropagation()
                    !item.audio_clip
                      ? onPasteMediaClip("audio")
                      : onClearMediaClip("audio")
                  }}
                />
                {!item.audio_clip ? (
                  <ImPaste className="input-deco-button" />
                ) : (
                  <MdClose className="input-deco-button" />
                )}
                <ReactTooltip
                  id="input-field-audio-clip"
                  scrollHide={false}
                  offset={{
                    top: -props.scrollOffset,
                  }}
                >
                  <span>
                      {!item.audio_clip
                      ? "Paste Link"
                      : "Clear Link"}
                  </span>
                </ReactTooltip>
              </div>
            </div>
          </div>
          <hr style={{ border: ".5px solid #fff" }} />
          <div className="d-flex flex-column">
            <div className="d-flex" style={{ gap: "8px" }}>
              <div className="d-flex flex-column">
                <label className="col-form-label font-weight-bold">
                  Detection Mode
                </label>
                <select
                  value={item.detection_mode}
                  className="form-control"
                  name="detection_mode"
                  style={{ width: "250px" }}
                  onChange={onChange}
                >
                  <option value={""}>Off</option>
                  <option value={"ML"}>Machine Learning</option>
                  <option value={"OCR"}>Text (OCR)</option>
                  <option value={"QR"}>QR</option>
                </select>
              </div>
              {item.detection_mode ? (
                <div className="d-flex flex-column" style={{ flex: 1 }}>
                  <label className="col-form-label font-weight-bold">
                    Detection Threshold
                  </label>
                  <div
                    className="d-flex"
                    style={{ gap: "8px", alignItems: "center" }}
                  >
                    <div
                      className="d-inline-flex align-items-center"
                      style={{
                        backgroundColor: "#333",
                        paddingRight: "0.5rem",
                      }}
                    >
                      <input
                        value={
                          typeof item.detection_threshold_low === "number"
                            ? item.detection_threshold_low
                            : DEFAULT_SLIDE_DETECTION_THRESHOLD_LOW
                        }
                        lang="en-US" // use dot for decimals
                        type="number"
                        step={1}
                        min={1}
                        max={
                          typeof item.detection_threshold_middle === "number"
                            ? item.detection_threshold_middle - 1
                            : DEFAULT_SLIDE_DETECTION_THRESHOLD_MIDDLE - 1
                        }
                        className="input-percentage"
                        name="detection_threshold_low"
                        onChange={onChange}
                      />
                      <span style={{ whiteSpace: "pre" }}>% low</span>
                      <MdOutlineSignalCellularAlt1Bar
                        size={36}
                        color={"#F4911E"}
                      />
                    </div>
                    <div
                      className="d-inline-flex align-items-center"
                      style={{
                        backgroundColor: "#333",
                        paddingRight: "0.5rem",
                      }}
                    >
                      <input
                        value={
                          typeof item.detection_threshold_middle === "number"
                            ? item.detection_threshold_middle
                            : DEFAULT_SLIDE_DETECTION_THRESHOLD_MIDDLE
                        }
                        lang="en-US" // use dot for decimals
                        type="number"
                        step={1}
                        min={
                          typeof item.detection_threshold_low === "number"
                            ? item.detection_threshold_low
                            : DEFAULT_SLIDE_DETECTION_THRESHOLD_LOW
                        }
                        max={
                          typeof item.detection_threshold_high === "number"
                            ? item.detection_threshold_high - 1
                            : DEFAULT_SLIDE_DETECTION_THRESHOLD_HIGH - 1
                        }
                        className="input-percentage"
                        name="detection_threshold_middle"
                        onChange={onChange}
                      />
                      <span style={{ whiteSpace: "pre" }}>% middle</span>
                      <MdOutlineSignalCellularAlt2Bar
                        size={36}
                        color={"#E5E510"}
                      />
                    </div>
                    <div
                      className="d-inline-flex align-items-center"
                      style={{
                        backgroundColor: "#333",
                        paddingRight: "0.5rem",
                      }}
                    >
                      <input
                        value={
                          typeof item.detection_threshold_high === "number"
                            ? item.detection_threshold_high
                            : DEFAULT_SLIDE_DETECTION_THRESHOLD_HIGH
                        }
                        lang="en-US" // use dot for decimals
                        type="number"
                        step={1}
                        min={
                          typeof item.detection_threshold_middle === "number"
                            ? item.detection_threshold_middle
                            : DEFAULT_SLIDE_DETECTION_THRESHOLD_HIGH
                        }
                        max={99}
                        className="input-percentage"
                        name="detection_threshold_high"
                        onChange={onChange}
                      />
                      <span style={{ whiteSpace: "pre" }}>% high</span>
                      <MdOutlineSignalCellularAlt size={36} color={"#4EC8AF"} />
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            {item.detection_mode ? (
              <>
                <div>
                  <label className="col-form-label font-weight-bold">
                    {item.detection_mode === "ML"
                      ? "Detection Model Name*"
                      : item.detection_mode === "OCR"
                      ? "Text*"
                      : item.detection_mode === "QR"
                      ? "Decoded Value*"
                      : ""}
                  </label>
                  <input
                    value={item.detection_model}
                    type="text"
                    required
                    className="form-control"
                    name="detection_model"
                    onChange={onChange}
                  />
                </div>
                {item.detection_mode === "ML" ? (
                  <div>
                    <label className="col-form-label font-weight-bold">
                      Detection Labels*
                    </label>
                    <input
                      value={item.detection_labels}
                      type="text"
                      required
                      className="form-control"
                      name="detection_labels"
                      onChange={onChange}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
          <hr style={{ border: ".5px solid #fff" }} />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label
              className="col-form-label font-weight-bold"
              style={{ paddingBottom: "0px" }}
            >
              Blocking Mode
            </label>
            <span
              className="text-left"
              style={{
                color: "#ccc",
                marginBottom: "10px",
                fontSize: "14px",
              }}
            >
              Prevent from switching to next slide
            </span>
            <div
              className="d-inline-flex align-items-center"
              style={{ gap: "0.5rem" }}
            >
              <select
                className="form-control"
                value={item.blocking_mode}
                name="blocking_mode"
                style={{ width: "100px" }}
                onChange={onChange}
              >
                <option value={""}>Off</option>
                <option value={"DETECTION"}>Detection</option>
                <option value={"SNAP"}>Snap</option>
              </select>
              {item.blocking_mode ? (
                <>
                  <HiOutlineQuestionMarkCircle
                    data-tip
                    data-for={`rtt-blocking-mode`}
                  />
                  <ReactTooltip
                    id={`rtt-blocking-mode`}
                    scrollHide={false}
                    place={"right"}
                    offset={{
                      top: -props.scrollOffset,
                    }}
                  >
                    <span>
                      {item.blocking_mode === "DETECTION"
                        ? "Prevent until labels are with a score greater than the threshold"
                        : item.blocking_mode === "SNAP"
                        ? "Prevent until a snapshot is taken and transmitted"
                        : ""}
                    </span>
                  </ReactTooltip>
                </>
              ) : (
                <></>
              )}
            </div>
            {item.blocking_mode === "DETECTION" ? (
              <>
                <label className="col-form-label font-weight-bold">
                  Labels to detect*
                </label>
                <input
                  required
                  value={item.blocking_labels}
                  type="text"
                  className="form-control"
                  name="blocking_labels"
                  onChange={onChange}
                />
              </>
            ) : (
              <></>
            )}
          </div>
          <hr style={{ border: ".5px solid #fff" }} />
          <div className="form-row">
            <div className="form-group col-sm-12">
              <div
                className="btn btn-block hmk-form-btn-ghosted"
                onMouseDown={onAddMedia}
              >
                Add Image
              </div>
            </div>
          </div>
          {item.content.map((c, idx) => (
            <FormMedia
              key={`${c.part_url}${idx}`}
              selected={props.selectedMediaIndex === idx}
              locked={props.sequence.lockedMedia.includes(
                c.layer_id + c.part_picture_id
              )}
              onLockMedia={() => {
                props.dispatch({
                  type: "TOGGLE_LOCK_MEDIA",
                  payload: c.layer_id + c.part_picture_id,
                })
              }}
              id={idx}
              picture_id={c.part_picture_id}
              layer_id={c.layer_id}
              part_url={c.part_url}
              compositing={c.compositing ? c.compositing : _compositing[0]}
              opacity={c.opacity ? c.opacity : DEFAULT_MEDIA_OPACITY}
              scale={c.scale ? c.scale : DEFAULT_MEDIA_SCALE}
              displayDelete={item.content.length > 1}
              openHMKComposer={openHMKComposer}
              onChange={onChange}
              onResetMedia={() => {
                onResetMedia(c, idx)
              }}
              onClearLink={onClearLink}
              onCopyLink={() => onCopyLink(c)}
              onPasteLink={onPasteLink}
              onClick={(idx) => {
                props.setSelectedMediaIndex(idx)
              }}
              onDeleteMedia={(part_url) => {
                part_url ? onDeleteMedia(idx) : triggerDeleteMedia(idx)
              }}
              onMoveMediaUp={(idx) => {
                if (props.selectedMediaIndex === idx) {
                  props.setSelectedMediaIndex((prev) => prev - 1)
                } else if (isIndex(props.selectedMediaIndex)) {
                  if (props.selectedMediaIndex + 1 <= idx) {
                    props.setSelectedMediaIndex((prev) => prev + 1)
                  } else if (props.selectedMediaIndex - 1 >= idx) {
                    props.setSelectedMediaIndex((prev) => prev - 1)
                  }
                }
                onMoveMediaUp(idx)
              }}
              scrollOffset={props.scrollOffset}
            />
          ))}
          <div className="form-row">
            <div className="form-group offset-sm-4 col-sm-4" type="button">
              <button className="btn hmk-form-btn" type="submit" autoFocus>
                Ok
              </button>
            </div>
          </div>
        </form>
      )}
    </AuthUserContext.Consumer>
  )
}

const Content = compose(withRouter, withFirebase)(EditUserContent)

const condition = (currentUser) => !!currentUser

export default withAuthorization(condition)(SlideContentEditor)
